<template>
    <div class="PropertyDefinitionEditHeader bg-white border-bottom">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="py-3 px-0">
                        <p class="mb-1 text-microcopy--70 cursor-pointer text-primary"
                           @click="goToSchemaDefinitionEdit">
                            <i class="fas fa-chevron-left"></i> {{ $t('All Properties') }}
                        </p>
                        <p class="mb-0 weight-7 size-5 line-height-2">{{ pageTitle }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

/**
 * Header for PropertyDefinitionEditView.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 29 July 2020
 * @lastModifiedAt 16 March 2021
 */
export default {
    name: 'PropertyDefinitionEditHeader',
    computed: {
        ...mapGetters('propertyDefinitionEdit', {
            pageTitle: 'pageTitle',

            // df-dynamic sub-module
            schemaDefinitionName: 'schemaDefinitionName'
        })
    },
    methods: {
        goToSchemaDefinitionEdit () {
            this.$router.push({
                name: 'schema-definition-edit',
                params: {
                    id: this.schemaDefinitionName
                }
            });
        }
    }
};
</script>
