<template>
    <div class="PropertyDefinitionEditForm bg-white border SweetModalWrapper--Basic"
         v-if="formCanBeDisplayed">

        <!-- FORM (ELEMENT UI) -->
        <el-form :model="formFields"
                 ref="propertyDefinitionEditForm"
                 :label-position="'top'"
                 class="cltlr-form-label-style-1"
                 :disabled="disableForm"
                 @submit.native.prevent>
            <!-- NOTIFICATIONS AND WARNINGS -->
            <div class="py-4 px-4 mb-0"
                 v-if="displayWarningAboutPredefined">
                <div class="alert C-Alert alert-warning mb-0">
                    <p class="mb-0">{{ $t('read_only_definition_and_predefined_property_warning_1') }}</p>
                </div>
            </div>
            <!-- LABEL AND DESCRIPTION -->
            <df-property-group :class="{ 'border-top': displayWarningAboutPredefined }"
                               name="basics"
                               :label="$t('pd_edit_title_Basics')"
                               :can-be-closed="false"
                               :opened="true">
                <div class="py-4 px-4" slot="body">
                    <el-form-item class="mb-0"
                                  :label="$t('Label')"
                                  prop="label"
                                  :rules="formFieldsRules.label"
                                  for="pdEditFormLabel">
                        <el-input id="pdEditFormLabel"
                                  v-model="formFields.label"></el-input>
                    </el-form-item>
                    <p class="mb-0 mt-3 small" v-if="displayTranslatedLabel"><b>{{ $t('Translated Label') }}</b>: {{ translatedLabel }}</p>
                </div>
            </df-property-group>
            <!-- GROUP -->
            <df-property-group name="group"
                               :label="formFields.groupNew ? $t('New Group') : $t('Group')"
                               :can-be-closed="false"
                               :opened="true">
                <div class="py-4 px-4" slot="body">
                    <!-- NEW GROUP -->
                    <div v-if="formFields.groupNew" key="group-new">
                        <el-form-item :label="$t('Group Label')"
                                      prop="groupLabel"
                                      :rules="formFieldsRules.groupLabel"
                                      for="pdEditFormGroupLabel">
                            <el-input id="pdEditFormGroupLabel"
                                      v-model="formFields.groupLabel"></el-input>
                        </el-form-item>
                        <p class="mb-0 text-microcopy--70 text-primary text-uppercase cursor-pointer"
                           @click="switchToExistingGroups"
                           v-if="!disableForm">{{ $t('df_edit_group_select_existing') }}</p>
                        <!-- it's expected to trigger change -->
                    </div>
                    <!-- EXISTING GROUP -->
                    <div v-else key="group-not-new">
                        <el-form-item :label="$t('Group')"
                                      prop="group"
                                      :rules="formFieldsRules.group"
                                      for="pdEditFormGroup">
                            <el-select id="pdEditFormGroup"
                                       class="w-100"
                                       :label="$t('Group')"
                                       v-model="formFields.group"
                                       @change="handleExistingGroupChange">
                                <el-option v-for="groupOption in groupOptions"
                                           :key="groupOption.value"
                                           :label="groupOption.label"
                                           :value="groupOption.value">{{ groupOption.label }}</el-option>
                            </el-select>
                        </el-form-item>
                        <p class="mb-0 text-microcopy--70 text-primary text-uppercase cursor-pointer"
                           @click="switchToNewGroup"
                           v-if="!disableForm">{{ $t('df_edit_group_create_new') }}</p>
                        <!-- it's expected to trigger change -->
                    </div>
                </div>
            </df-property-group>
            <!-- TYPE -->
            <df-property-group name="type"
                               :label="$t('Type')"
                               :can-be-closed="false"
                               :opened="true">
                <div class="py-4 px-4" slot="body">
                    <el-form-item :class="{ 'mb-0': !displayEnumerationValues }"
                                  :label="$t('Type')"
                                  prop="type"
                                  :rules="formFieldsRules.type"
                                  for="pdEditFormType">
                        <el-select id="pdEditFormType"
                                   class="w-100"
                                   :label="$t('Type')"
                                   v-model="formFields.type"
                                   @change="handleTypeChange"
                                   :disabled="disableForCustom">
                            <el-option v-for="typeOption in typeOptions"
                                       :key="typeOption.value"
                                       :label="typeOption.label"
                                       :value="typeOption.value">{{ typeOption.label }}</el-option>
                        </el-select>
                    </el-form-item>

                    <!-- ENUMERATION VALUES -->
                    <div v-if="displayEnumerationValues">
                        <el-form-item class="mb-0"
                                      id="pdEditFormEnumerationValues-ElFormItem"
                                      :label="$t('Options')"
                                      prop="enumerationValues"
                                      :rules="formFieldsRules.enumerationValues"
                                      for="pdEditFormEnumerationValues">
                            <table class="table border mb-0">
                                <thead class="bg-light">
                                <tr>
                                    <th v-if="!disableForm && !disableForCustom"></th>
                                    <th>{{ $t('Label') }}</th>
                                    <th>{{ $t('Internal Value') }}</th>
                                    <th v-if="!disableForm && !disableForCustom"></th>
                                </tr>
                                </thead>
                                <!-- draggable is also the tbody -->
                                <draggable tag="tbody"
                                           v-model="formFields.enumerationValues"
                                           :disabled="disableForm"
                                           handle=".dnd-handle"
                                           ghost-class="Draggable-Ghost--Default"
                                           :force-fallback="true">
                                    <tr v-for="(enumerationValue, index) in formFields.enumerationValues"
                                        :key="enumerationValue.rId">
                                        <td class="align-middle cursor-grab text-center dnd-handle"
                                            v-if="!disableForm && !disableForCustom">
                                            <small class="text-muted">
                                                <i class="fas fa-grip-lines"></i>
                                            </small>
                                        </td>
                                        <td class="align-middle">
                                            <el-input v-model="enumerationValue.label"
                                                      :placeholder="$t('Enter label')"></el-input>
                                        </td>
                                        <td class="align-middle">
                                            <el-input v-model="enumerationValue.value"
                                                      :placeholder="$t('Enter internal value')"
                                                      :disabled="disableForCustom"></el-input>
                                        </td>
                                        <td class="align-middle cursor-pointer text-center"
                                            :title="$t('common_action_remove')"
                                            @click="removeEnumerationValueByIndex(index)"
                                            v-if="!disableForm && !disableForCustom">
                                            <small class="text-muted">
                                                <i class="fas fa-times"></i>
                                            </small>
                                        </td>
                                    </tr>
                                </draggable>
                            </table>
                        </el-form-item>
                        <button type="button"
                                class="btn btn-sm btn-primary mt-2 mb-0"
                                @click="addEnumerationValue"
                                v-if="!disableForm && !disableForCustom">{{ $t('Add option') }}</button>
                        <div class="mt-4">
                            <p class="text-muted small mb-0">* {{ $t('prop_def_edit_enumeration_notice_1') }}</p>
                            <p class="text-muted small mb-0">** {{ $t('prop_def_edit_enumeration_notice_2') }}</p>
                        </div>
                    </div>
                </div>
            </df-property-group>
            <!-- ADVANCED AND FLAGS AND SETTINGS -->
            <df-property-group name="advanced"
                               :label="$t('pd_edit_title_Advanced')"
                               :can-be-closed="true"
                               :opened="groups.advanced"
                               v-on:toggle="groupToggle">
                <div class="py-4 px-4" slot="body">
                    <div v-if="formFields.groupNew">
                        <el-form-item :label="$t('Group Internal Name')"
                                      prop="groupName"
                                      :rules="formFieldsRules.groupName"
                                      for="pdEditFormGroupName">
                            <el-input id="pdEditFormGroupName"
                                      v-model="formFields.groupName"></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item :label="$t('Internal Name')"
                                  prop="name"
                                  :rules="formFieldsRules.name"
                                  for="pdEditFormName">
                        <el-input id="pdEditFormName"
                                  v-model="formFields.name"
                                  :disabled="disableForCustom"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('pd_edit_label_Personalization')"
                                  prop="personalization"
                                  :rules="formFieldsRules.personalization"
                                  for="pdEditFormPersonalization">
                        <el-switch id="pdEditFormPersonalization"
                                   v-model="formFields.personalization"></el-switch>
                    </el-form-item>
                    <el-form-item class="mb-0"
                                  :label="$t('pd_edit_label_Queryable')"
                                  prop="queryable"
                                  :rules="formFieldsRules.queryable"
                                  for="pdEditFormQueryable">
                        <el-switch id="pdEditFormQueryable"
                                   v-model="formFields.queryable"></el-switch>
                    </el-form-item>
                </div>
            </df-property-group>
            <!-- ACTIONS -->
            <el-form-item class="py-4 px-4 mb-0">
                <button type="button"
                        class="btn btn-primary mr-1"
                        :disabled="disableForm || formNotChanged"
                        @click="submitCreateUpdate">
                    {{ isCreate ? $t('common_action_create') : $t('common_action_save') }}
                </button>
                <button type="button"
                        class="btn btn-link text-danger mr-2"
                        :disabled="disableForm"
                        @click="submitDelete"
                        v-if="!isCreate">
                    {{ $t('common_action_delete') }}
                </button>
            </el-form-item>
        </el-form>

        <!-- CONFIRMATION MODAL / CREATE -->
        <sweet-modal :blocking="true"
                     :pulse-on-block="true"
                     :hide-close-button="true"
                     modal-theme="light"
                     overlay-theme="light"
                     ref="confirmationModalCreate">
            <div slot="title">
                <p class="mb-0 weight-5 size-6">{{ $t('Create Property') }}</p>
            </div>
            <div class="position-relative">
                <ul class="mb-0">
                    <li>{{ $t('pro_def_edit_modal_create_delete_notice_1') }}</li>
                    <li>{{ $t('pro_def_edit_modal_create_notice_1') }}</li>
                    <li>{{ $t('pro_def_edit_modal_create_delete_notice_2') }}</li>
                </ul>
            </div>
            <div slot="button">
                <button class="btn btn-primary"
                        @click="createPropertyGroup">{{ $t('common_action_create') }}</button>
                <button class="btn btn-link"
                        @click="cancelCreateConfirmationModal">{{ $t('common_action_cancel') }}</button>
            </div>
        </sweet-modal>

        <!-- CONFIRMATION MODAL / DELETE -->
        <sweet-modal :blocking="true"
                     :pulse-on-block="true"
                     :hide-close-button="true"
                     modal-theme="light"
                     overlay-theme="light"
                     ref="confirmationModalDelete">
            <div slot="title">
                <p class="mb-0 weight-5 size-6">{{ $t('Delete Property') }}</p>
            </div>
            <div class="position-relative">
                <ul class="mb-0">
                    <li>{{ $t('pro_def_edit_modal_create_delete_notice_1') }}</li>
                    <li>{{ $t('pro_def_edit_modal_delete_notice_1') }}</li>
                    <li>{{ $t('pro_def_edit_modal_create_delete_notice_2') }}</li>
                </ul>
            </div>
            <div slot="button">
                <button class="btn btn-danger" @click="deletePropertyDefinition">{{ $t('common_action_delete') }}</button>
                <button class="btn btn-link" @click="cancelDeleteConfirmationModal">{{ $t('common_action_cancel') }}</button>
            </div>
        </sweet-modal>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import { SweetModal } from 'sweet-modal-vue';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import i18n from '@/common/plugins/i18n';
import RandomUtils from '@/common/utils/random.utils';
import ElValidationUtils from '@/common/utils/el-validation.utils';
import DfPropertyGroup from '@/components/df-property-group';
import DomainTranslations from '@/modules/DomainTranslations';
import PdFormHelper from './pd-form-helper';
import debounce from 'lodash/debounce';

/**
 * PropertyDefinition create/edit Form.
 *
 * @future implementations and improvements:
 * - On submit, if 'advanced' section has fields with errors, open it.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 29 July 2020
 * @lastModifiedAt Thursday 16 December 2021
 */
export default {
    name: 'PropertyDefinitionEditForm',
    components: {
        draggable,
        SweetModal,
        DfPropertyGroup
    },
    data () {
        const enumerationValuesMaxLengthElValidator = (rule, value, callback) => {
            const maxLength = 100;
            for (let i = 0; i < value.length; i++) {
                if (value[i].label.trim().length > maxLength || value[i].value.trim().length > maxLength) {
                    callback(new Error(i18n.t('prop_def_edit_form_enumValues_max_length', {
                        max: maxLength
                    })));
                    return;
                }
            }

            callback();
        };

        const enumerationValuesPatternElValidator = (rule, value, callback) => {
            const valueRegex = RegExp('^[A-Z][A-Z0-9]+(?:_[A-Z0-9]+)*$');
            const invalidValues = [];

            for (let i = 0; i < value.length; i++) {
                const cleanedValue = value[i].value.trim();
                if (!valueRegex.test(cleanedValue)) {
                    invalidValues.push({
                        index: i,
                        cleanedValue: cleanedValue,
                        value: value[i].value,
                        label: value[i].label
                    });
                }
            }

            // @future use invalidValues and index to mark only invalid inputs.
            const valuesListLiteral = invalidValues.map(item => item.cleanedValue).join(', ');
            const translatedMessage = i18n.t('prop_def_edit_form_enumValues_value_invalid_regex', {
                valuesListLiteral: valuesListLiteral
            });
            if (invalidValues.length > 0) callback(new Error(translatedMessage));
            else callback();
        };

        const enumerationValuesUniquenessElValidator = (rule, value, callback) => {
            const labelsList = [];
            const valuesList = [];

            for (let i = 0; i < value.length; i++) {
                labelsList.push(value[i].label.trim());
                valuesList.push(value[i].value.trim());
            }

            // Check if there are duplicates (labels).
            if (labelsList.some((val, i) => labelsList.indexOf(val) !== i)) {
                const valuesListLiteral = [].join(', ');
                const translatedMessage = i18n.t('prop_def_edit_form_enumValues_labels_unique', {
                    valuesListLiteral: valuesListLiteral
                });
                callback(new Error(translatedMessage));
                return;
            }

            // Check if there are duplicates (values).
            if (valuesList.some((val, i) => valuesList.indexOf(val) !== i)) {
                const valuesListLiteral = [].join(', ');
                const translatedMessage = i18n.t('prop_def_edit_form_enumValues_values_unique', {
                    valuesListLiteral: valuesListLiteral
                });
                callback(new Error(translatedMessage));
                return;
            }

            callback();
        };

        return {
            // UI groups
            groups: {
                advanced: false
            },

            // Computed during user interactions
            formNotChanged: true,

            // Form options and settings
            domain: null,
            formCanBeDisplayed: true,
            isCreate: false,
            displayTranslatedLabel: false,
            translatedLabel: null,
            pgInitialName: '',
            pdInitialName: '',
            initialGroup: 'general',
            initialDisplayOrder: 100,
            groupOptions: [
                {
                    value: 'general',
                    label: 'General'
                }
            ],
            // labels are set dynamically (translated)
            typeOptions: [
                {
                    value: 'true_STRING_false',
                    label: 'Short Text List'
                },
                {
                    value: 'STRING_false',
                    label: 'Short Text'
                },
                {
                    value: 'STRING_true',
                    label: 'Long Text'
                },
                {
                    value: 'LONG',
                    label: 'Number'
                },
                // DOUBLE is not yet supported.
                {
                    value: 'BOOLEAN',
                    label: 'Boolean'
                },
                {
                    value: 'INSTANT',
                    label: 'Date'
                },
                {
                    value: 'ENUMERATION',
                    label: 'Enumeration'
                }
                // Any other combination is theoretically unreachable.
            ],
            displayEnumerationValues: false,
            disableForm: false,
            displayWarningAboutPredefined: false,
            // A flag that disables some controls (not all) if propertyDefinition is created by any user.
            disableForCustom: false,

            pdCount: 0,
            pgCount: 0,

            // Form data and validation
            formFieldsBase: null,
            formFields: {
                name: '',
                label: '',
                displayOrder: 100,
                group: 'general',
                // In case of new group.
                groupNew: false,
                groupName: null,
                groupLabel: null,
                groupDisplayOrder: 100,
                type: 'STRING_false',
                // 'enumerationValues' and 'enumerationValuesLabels'
                enumerationValues: [],
                // Flags //////////
                personalization: false,
                queryable: false
            },
            formFieldsRules: {
                name: [
                    ElValidationUtils.requiredValidator(),
                    ElValidationUtils.nonBlankValidator(),
                    ElValidationUtils.lengthValidator(2, 100),
                    ElValidationUtils.dfPgPdNameValidator()
                    // extra validators are pushed based on context.
                ],
                label: [
                    ElValidationUtils.requiredValidator(),
                    ElValidationUtils.nonBlankValidator(),
                    ElValidationUtils.lengthValidator(2, 100)
                ],
                displayOrder: [],
                group: [
                    ElValidationUtils.requiredValidator()
                ],
                groupNew: [],
                groupName: [
                    ElValidationUtils.requiredValidator(),
                    ElValidationUtils.nonBlankValidator(),
                    ElValidationUtils.lengthValidator(2, 100),
                    ElValidationUtils.dfPgPdNameValidator()
                    // extra validators are pushed based on context.
                ],
                groupLabel: [
                    ElValidationUtils.requiredValidator(),
                    ElValidationUtils.nonBlankValidator(),
                    ElValidationUtils.lengthValidator(2, 100)
                ],
                groupDisplayOrder: [],
                type: [
                    ElValidationUtils.requiredValidator()
                ],
                enumerationValues: [
                    ElValidationUtils.mustBeListValidator(),
                    ElValidationUtils.requiredValidator(),
                    {
                        validator: enumerationValuesMaxLengthElValidator,
                        trigger: 'blur'
                    },
                    {
                        validator: enumerationValuesPatternElValidator,
                        trigger: 'blur'
                    },
                    {
                        validator: enumerationValuesUniquenessElValidator,
                        trigger: 'blur'
                    }
                ],
                personalization: [],
                queryable: []
            }
        };
    },
    beforeMount () {
        this.synchronize();
        this.setExtraFormFieldRules();

        // The listen to changes and use mutations to modify property.
        this.$watch('formFields', {
            handler: debounce(function (ignoredValue) {
                this.formNotChanged = isEqual(this.formFields, this.formFieldsBase);
            }, 300),
            deep: true
        });
    },
    methods: {
        /**
         * Synchronizes the component data.
         */
        synchronize () {
            const schemaDefinition = this.$store.getters['propertyDefinitionEdit/schemaDefinition'];
            const propertyDefinition = this.$store.getters['propertyDefinitionEdit/propertyDefinition'];

            // Check instances (false is theoretically unreachable).
            if (schemaDefinition === null || propertyDefinition === null) {
                this.formCanBeDisplayed = false;
                return;
            }

            this.domain = schemaDefinition.name;
            this.isCreate = this.$store.getters['propertyDefinitionEdit/isCreate'];

            if (propertyDefinition.predefined === true) {
                this.translatedLabel = DomainTranslations
                    .dfPd(schemaDefinition.name, propertyDefinition.name, propertyDefinition.label);

                if (this.translatedLabel !== propertyDefinition.label) this.displayTranslatedLabel = true;
            }

            this.pgCount = schemaDefinition.propertyGroups.length;
            this.pdCount = schemaDefinition.propertyDefinitions.length;

            this.groupOptions = schemaDefinition.propertyGroups
                .map(function (propertyGroup) {
                    return {
                        value: propertyGroup.name,
                        label: DomainTranslations.dfPg(schemaDefinition.name, propertyGroup.name, propertyGroup.label)
                    };
                });

            // Translate typeOptions labels.
            this.typeOptions = this.typeOptions
                .map(function (typeOption) {
                    typeOption.label = DomainTranslations.propertyTypeLabelByKey(typeOption.value, typeOption.label);
                    return typeOption;
                });

            // @future POTENTIAL BUG But if another user creates a new instance while this user editing this one?
            // BUG 2020-07-30 adding and removing properties is a dynamic operation.
            // Added as suffix the SN can cause error (name not unique).
            this.pgInitialName = 'group' + (this.pgCount + 1) + '_' + RandomUtils.getRandomInteger(1, 99);
            this.pdInitialName = 'property' + (this.pdCount + 1) + '_' + RandomUtils.getRandomInteger(1, 99);

            this.disableForm = propertyDefinition.readOnlyDefinition === true;
            this.displayWarningAboutPredefined = propertyDefinition.readOnlyDefinition === true && propertyDefinition.predefined;
            if (this.disableForm === true) this.disableForCustom = true;
            else this.disableForCustom = this.isCreate === false; // Update.

            // Map PropertyDefinition to formFields.
            this.formFields.name = propertyDefinition.name;
            this.formFields.label = propertyDefinition.label;
            this.formFields.group = propertyDefinition.group;
            this.formFields.groupNew = false;
            this.formFields.groupName = null;
            this.formFields.groupLabel = null;
            switch (propertyDefinition.type) {
            case 'STRING':
                this.formFields.type = propertyDefinition.type + '_' + get(propertyDefinition, 'sourceSpecifics.specifics.bigString', false).toString();
                if (propertyDefinition.list === true) this.formFields.type = 'true_' + this.formFields.type;
                break;
            case 'LONG':
            case 'BOOLEAN':
            case 'INSTANT':
            case 'ENUMERATION':
                this.formFields.type = propertyDefinition.type;
                break;
            case 'DOUBLE':
            default:
                // Theoretically unreachable.
                // null it's okay because form requires this field in order to be submitted.
                this.formFields.type = null;
                break;
            }
            if (propertyDefinition.type === 'ENUMERATION') {
                this.displayEnumerationValues = true;
            }
            this.formFields.enumerationValues = cloneDeep(propertyDefinition.enumerationValues)
                .map(function (enumValue) {
                    const enumerationValueLabel = propertyDefinition.enumerationValuesLabels[enumValue];
                    let label;
                    if (propertyDefinition.predefined === true) {
                        label = DomainTranslations.enumeration(schemaDefinition.name, enumValue, propertyDefinition.name, enumerationValueLabel);
                    } else {
                        label = enumerationValueLabel;
                    }
                    return {
                        rId: RandomUtils.getUniqueId(),
                        value: enumValue,
                        label: label
                    };
                });
            this.formFields.personalization = get(propertyDefinition, 'sourceSpecifics.specifics.personalization', false);
            this.formFields.queryable = get(propertyDefinition, 'sourceSpecifics.specifics.queryable', false);

            // In case a new group is created.
            this.formFields.groupDisplayOrder = this.pgCount + 1;

            // Set initial values for some fields if it's create.
            if (this.isCreate) {
                this.formFields.name = this.pdInitialName;

                this.formFields.displayOrder = this.pdCount + 1;
            } else {
                this.formFields.displayOrder = propertyDefinition.displayOrder;
            }

            // we need in case of group change.
            this.initialGroup = this.formFields.group;
            this.initialDisplayOrder = this.formFields.displayOrder;

            // Keep the initial object (in order to compare it with working object).
            this.formFieldsBase = cloneDeep(this.formFields);
        },
        setExtraFormFieldRules () {
            const schemaDefinition = this.$store.getters['propertyDefinitionEdit/schemaDefinition'];
            if (schemaDefinition === null) return;

            this.isCreate = this.$store.getters['propertyDefinitionEdit/isCreate'];
            if (this.isCreate === false) return; // Set only in 'create' as in any other case they cannot be changed.

            const pdNameList = schemaDefinition.propertyDefinitions.map(function (propertyDefinition) {
                return propertyDefinition.name;
            });
            // const pdLabelList = []; // @future add unique validation for PD label?
            const pgNameList = schemaDefinition.propertyGroups.map(function (propertyGroup) {
                return propertyGroup.name;
            });
            // const pgLabelList = []; // @future add unique validation for PG label?

            this.formFieldsRules.name.push(ElValidationUtils.dfPgPdUniqueNameInListV0Validator(pdNameList));
            this.formFieldsRules.groupName.push(ElValidationUtils.dfPgPdUniqueNameInListV0Validator(pgNameList));
        },

        // UI handlers and controls //////////
        groupToggle ($event) {
            if ($event === null || $event === undefined) return;
            if (this.groups.hasOwnProperty($event.name)) {
                this.groups[$event.name] = $event.newState;
            }
        },

        // Form Handlers //////////
        // They ensure the validity of form data.
        // They are triggered by specific form-control events
        switchToNewGroup () {
            this.formFields.groupNew = true;
            // this.formFields.group = 'general'; // do not reset (necessary in case of 'update').
            this.formFields.groupName = this.pgInitialName;
            this.formFields.groupLabel = '';

            this.handleExistingGroupChange();
        },
        switchToExistingGroups () {
            this.formFields.groupNew = false;
            // this.formFields.group = 'general'; // do not reset (necessary in case of 'update').
            this.formFields.groupName = null;
            this.formFields.groupLabel = null;

            this.handleExistingGroupChange();
        },
        // If a new group or a different is selected ensure that the field is last in order.
        handleExistingGroupChange () {
            if (this.formFields.groupNew === true) {
                this.formFields.displayOrder = this.pdCount + 1;
                return;
            }

            if (this.formFields.group === this.initialGroup) {
                this.formFields.displayOrder = this.initialDisplayOrder;
            } else {
                this.formFields.displayOrder = this.pdCount + 1;
            }
        },
        handleTypeChange ($event) {
            if (typeof $event !== 'string') return;

            // Settings
            this.displayEnumerationValues = false;

            // Form fields.
            this.formFields.enumerationValues = [];

            switch ($event) {
            case 'true_STRING_false':
            case 'STRING_false':
            case 'STRING_true':
            case 'LONG':
            case 'BOOLEAN':
            case 'INSTANT':
                // Nothing to do yet.
                break;
            case 'ENUMERATION':
                this.addEnumerationValue(); // add one option (good UX).
                this.displayEnumerationValues = true;
                break;
            default:
                break; // Theoretically unreachable.
            }
        },
        addEnumerationValue () {
            const count = this.formFields.enumerationValues.length + 1;
            this.formFields.enumerationValues.push({
                rId: RandomUtils.getUniqueId(),
                value: 'VALUE_' + count,
                label: ''
            });
        },
        removeEnumerationValueByIndex (index) {
            if (typeof index !== 'number') return;
            // @future check if it's in range.
            this.formFields.enumerationValues.splice(index, 1);

            this.triggerFormValidation();
        },

        // Form Actions //////////
        triggerFormValidation () {
            this.$refs['propertyDefinitionEditForm'].validate(() => void 0);
        },
        submitCreateUpdate () {
            this.$refs['propertyDefinitionEditForm'].validate((valid) => {
                if (valid) {
                    // Display confirmation modal.
                    if (this.isCreate) this.$refs.confirmationModalCreate.open();
                    // Proceed to operation without confirmation.
                    else this.createPropertyGroup();
                }
            });
        },
        submitDelete () {
            // Display confirmation modal.
            this.$refs.confirmationModalDelete.open();
        },

        // Operations //////////
        createPropertyGroup () {
            this.ensureAllModalsClosed();

            const create = this.isCreate;
            const instance = PdFormHelper.mapFormFieldsToNewPropertyGroup(cloneDeep(this.formFields));
            if (instance === null) {
                if (create === true) this.createPropertyDefinition();
                else this.updatePropertyDefinition();
            } else {
                this.$store.commit('propertyDefinitionEdit/setPropertyGroupDTO', instance);
                this.$store.dispatch('propertyDefinitionEdit/createPropertyGroup')
                    .then(() => {
                        this.toastDefault('Property Group created');
                        if (create === true) this.createPropertyDefinition();
                        else this.updatePropertyDefinition();
                    })
                    .catch((reason) => {
                        this.toastErrorFromError(reason);
                    });
            }
        },
        createPropertyDefinition () {
            const instance = PdFormHelper.mapFormFieldsToNewPropertyDefinition(cloneDeep(this.formFields));
            // @future Validate result?
            // @future Convert to Update DTO?
            this.$store.commit('propertyDefinitionEdit/setPropertyDefinitionCreateRequest', instance);
            this.$store.dispatch('propertyDefinitionEdit/createPropertyDefinition')
                .then(() => {
                    this.toastDefault('Property Definition created');
                    this.invalidateDfPgPdStoreModules();
                    this.$router.push(this.getRedirectToObj());
                })
                .catch((reason) => {
                    this.toastErrorFromError(reason);
                });
        },
        updatePropertyDefinition () {
            const propertyDefinition = this.$store.getters['propertyDefinitionEdit/propertyDefinition'];
            // At this point propertyDefinition cannot be null.
            const instance = PdFormHelper
                .mapFormFieldsToExistingPropertyDefinition(cloneDeep(this.formFields), cloneDeep(propertyDefinition));
            // @future Validate result?
            // @future Convert to Update DTO?
            this.$store.commit('propertyDefinitionEdit/setPropertyDefinitionUpdateRequest', instance);
            this.$store.dispatch('propertyDefinitionEdit/updatePropertyDefinition')
                .then(() => {
                    this.toastDefault('Property Definition updated');
                    this.invalidateDfPgPdStoreModules();
                    this.$router.push(this.getRedirectToObj());
                })
                .catch((reason) => {
                    this.toastErrorFromError(reason);
                });
        },
        deletePropertyDefinition () {
            this.ensureAllModalsClosed();

            this.$store.dispatch('propertyDefinitionEdit/deletePropertyDefinition')
                .then(() => {
                    this.toastDefault('Property Definition deleted');
                    this.invalidateDfPgPdStoreModules();
                    this.$router.push(this.getRedirectToObj());
                })
                .catch((reason) => {
                    this.toastErrorFromError(reason);
                });
        },

        // Modal Cancel Controls /////////
        ensureAllModalsClosed () {
            this.cancelCreateConfirmationModal();
            this.cancelDeleteConfirmationModal();
        },
        cancelCreateConfirmationModal () {
            this.$refs.confirmationModalCreate.close();
        },
        cancelDeleteConfirmationModal () {
            this.$refs.confirmationModalDelete.close();
        },

        // Form Actions Helpers //////////
        invalidateDfPgPdStoreModules () {
            this.$store.dispatch('application/signalToInvalidateSchemaDefinitionData');
        },
        getRedirectToObj () {
            return {
                name: 'schema-definition-edit',
                params: {
                    id: this.domain
                },
                query: {
                    pgName: this.formFields.groupNew === true ? this.formFields.groupName : this.formFields.group
                }
            };
        }
    }
};
</script>

<style lang="scss">
    .PropertyDefinitionEditForm {

        .el-form-item__error {
            position: relative;
            line-height: 1.6 !important;
        }
    }
</style>
