/****************************************************************************************************
 * PropertyDefinitionEdit form helper
 *
 * IMPORTANT:
 * - Helpers assume valid formFields!
 * - We assume that the provided PropertyDefinition has 'readOnlyDefinition' set to false.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 29 July 2020
 * @lastModifiedAt 16 March 2021
 ****************************************************************************************************/

import DomainModel from '@/common/logic/domain-models';

const PdFormHelper = {};

/**
 * Maps formFields to a new PropertyDefinition instance.
 *
 * ATTENTION: This is not working when updating a PropertyDefinition.
 * This methods return an instance of PropertyDefinition and not the create DTO.
 *
 * Normally we should have convert formFields to DTO.
 * But PropertyDefinition is required for preview components.
 */
PdFormHelper.mapFormFieldsToNewPropertyDefinition = function (formFields) {
    const propertyDefinition = DomainModel.getPropertyDefinition();

    // Initialize all PropertyDefinition fields. Some of them are set later based on user selection.
    propertyDefinition.name = formFields.name;
    propertyDefinition.label = formFields.label;
    propertyDefinition.description = ''; // @future SET.
    propertyDefinition.group = formFields.groupNew === true ? formFields.groupName : formFields.group;
    propertyDefinition.displayOrder = formFields.displayOrder;
    propertyDefinition.type = 'STRING';
    propertyDefinition.list = false;
    propertyDefinition.nullable = false;
    propertyDefinition.defaultValue = '';
    propertyDefinition.enumerationValues = [];
    propertyDefinition.enumerationValuesLabels = {};
    propertyDefinition.sourceSpecifics.specifics = {
        personalization: formFields.personalization,
        queryable: formFields.queryable,
        bigString: false
    };

    switch (formFields.type) {
    case 'true_STRING_false':
        propertyDefinition.list = true;
        break;
    case 'STRING_false':
        break;
    case 'STRING_true':
        propertyDefinition.sourceSpecifics.specifics.bigString = true;
        break;
    case 'LONG':
        propertyDefinition.type = 'LONG';
        propertyDefinition.defaultValue = '0';
        break;
    case 'BOOLEAN':
        propertyDefinition.type = 'BOOLEAN';
        propertyDefinition.defaultValue = 'false';
        break;
    case 'INSTANT':
        propertyDefinition.type = 'INSTANT';
        propertyDefinition.nullable = true;
        propertyDefinition.defaultValue = null;
        break;
    case 'ENUMERATION':
        propertyDefinition.type = 'ENUMERATION';
        // At this point 'enumerationValues' cannot be empty or null.
        propertyDefinition.defaultValue = formFields.enumerationValues[0].value;
        propertyDefinition.enumerationValues = formFields.enumerationValues
            .map(function (item) {
                return item.value;
            });
        propertyDefinition.enumerationValuesLabels = formFields.enumerationValues
            .reduce(function (accumulator, current) {
                accumulator[current.value] = current.label;
                return accumulator;
            }, {});
        break;
    case 'DOUBLE':
    default:
        // Theoretically unreachable.
        throw new Error('type is not valid!');
    }

    return propertyDefinition;
};

/**
 * Maps formFields to an existing PropertyDefinition instance.
 *
 * Normally we should have convert formFields to DTO.
 * But PropertyDefinition is required for preview components.
 */
PdFormHelper.mapFormFieldsToExistingPropertyDefinition = function (formFields, propertyDefinition) {
    // DO NOT CHANGE NAME.
    propertyDefinition.label = formFields.label;
    propertyDefinition.description = ''; // @future SET.
    propertyDefinition.group = formFields.groupNew === true ? formFields.groupName : formFields.group;
    propertyDefinition.displayOrder = formFields.displayOrder;
    propertyDefinition.enumerationValuesLabels = formFields.enumerationValues
        .reduce(function (accumulator, current) {
            accumulator[current.value] = current.label;
            return accumulator;
        }, {});
    propertyDefinition.sourceSpecifics.specifics = {
        personalization: formFields.personalization,
        queryable: formFields.queryable
    };
    return propertyDefinition;
};

/**
 * Maps formFields to a new PropertyGroup instance.
 * If null returned means that the PropertyGroup should no be created.
 */
PdFormHelper.mapFormFieldsToNewPropertyGroup = function (formFields) {
    if (formFields.groupNew === false) return null;
    const propertyGroup = DomainModel.getPropertyGroup();
    propertyGroup.name = formFields.groupName;
    propertyGroup.description = '';
    propertyGroup.label = formFields.groupLabel;
    propertyGroup.groupDisplayOrder = formFields.groupDisplayOrder;
    return propertyGroup;
};

export default PdFormHelper;
