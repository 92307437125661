<template>
    <div class="PropertyDefinitionEditView ViewWrapper LwHaB">
        <clv-head-meta :title="[
            stateIsChanging === true ? $t('common_state_loading') + '...' : null,
            pageTitle,
            $t('Not Found')
        ]"></clv-head-meta>

        <!-- VIEW LOADER -->
        <element-loading :active="stateIsChanging" :is-full-view="true" :lock-parent="true"></element-loading>

        <!-- EMPTY STATE 2020-07-19 -->
        <simple-state-not-found :for-view="true" v-if="displayEmptyState">
            <div class="mt-2" slot="action">
                <router-link tag="a" :to="{ name: 'tools' }"><i class="fas fa-chevron-left"></i> {{ $t('Tools') }}</router-link>
            </div>
        </simple-state-not-found>

        <!-- MAIN -->
        <div class="d-md-flex flex-md-column LwHaB-Main Disable-md" v-if="displayMain">
            <property-definition-edit-header></property-definition-edit-header>
            <div class="flex-grow-1 flex-shrink-1 LwHaB-Main__Body">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-8 col-lg-9">
                            <div class="mt-3"></div>
                            <property-definition-edit-form></property-definition-edit-form>
                            <div class="mb-3"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RouterUtils from '@/common/utils/router.utils';
import PropertyDefinitionEditHeader from './PropertyDefinitionEditHeader';
import PropertyDefinitionEditForm from './PropertyDefinitionEditForm';

/**
 * SchemaDefinition edit view.
 *
 * @author Dimitris Gkoulis <gkould@gmail.com>
 * @createdAt 29 July 2020
 * @lastModifiedAt 16 March 2021
 */
export default {
    name: 'PropertyDefinitionEditView',
    components: {
        PropertyDefinitionEditHeader,
        PropertyDefinitionEditForm
    },
    computed: {
        ...mapGetters('propertyDefinitionEdit', {
            pageTitle: 'pageTitle',

            stateIsChanging: 'stateIsChanging',
            displayEmptyState: 'displayEmptyState',
            displayMain: 'displayMain'
        })
    },
    watch: {
        '$route': {
            handler: function (to, from) {
                const result = RouterUtils.resourceIdHelper(to, from);
                this.$store.commit('propertyDefinitionEdit/setDomainAndNameById', result.toId);

                // On route change, initialize.
                if (result.mustInitialize) {
                    this.$store.dispatch('propertyDefinitionEdit/resetModule').then(() => {
                        this.$store.commit('propertyDefinitionEdit/setDomainAndNameById', result.toId);
                        this.$store.dispatch('propertyDefinitionEdit/initializeModule')
                            .then(() => void 0).catch(() => void 0);
                    }).catch(() => void 0);
                }
            },
            immediate: true
        }
    }
};
</script>
